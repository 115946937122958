import Stickyfill from 'stickyfilljs/dist/stickyfill.min.js';

export function observeFunc() {
  const clientHeight = document.documentElement.clientHeight;
  const header = document.querySelector('#header');
  let option = {
    root: document.querySelector("#observerArea"),
    // 上下100px、左右20px手前で発火
    rootMargin: "100px 20px",
    // 交差領域が50%変化するたびに発火
    threshold: [0, 0.5, 1.0]
  };
  let observer = new IntersectionObserver((changes) => {
    for (let change of changes) {
      let rect = change.target.getBoundingClientRect();
      let h = (0 < rect.top && rect.top < clientHeight) // 対象の上端は表示領域に入っている
        ||
        (0 < rect.bottom && rect.bottom < clientHeight) // 対象の下端は表示領域に入っている
        ||
        (0 > rect.top && rect.bottom > clientHeight); // 上端下端も表示されてないがその間が表示されている
      if (h) {
        header.classList.remove('scrolled');
      } else {
        header.classList.add('scrolled');
      }
    }
  }, option);
  let target = document.querySelector('.xyz');
  observer.observe(target);
}

/** スクロール一時禁止 **/
let scroll_control = function (e) {
  e.preventDefault();
}
export function no_scroll() {
  document.addEventListener("mousewheel", scroll_control, {
    passive: false
  });
  document.addEventListener("touchmove", scroll_control, {
    passive: false
  });
}
export function return_scroll() {
  document.removeEventListener("mousewheel", scroll_control, {
    passive: false
  });
  document.removeEventListener('touchmove', scroll_control, {
    passive: false
  });
}

export function sticyFunc() {
  // position: stickyがブラウザで使えるかチェックするための関数
  function detectSticky() {
    const div = document.createElement('div');
    div.style.position = 'sticky';
    return div.style.position.indexOf('sticky') !== -1;
  }
  // もしブラウザでposition: stickyが使えなければ、callStickyState関数を呼び出す
  if (!detectSticky()) {
    let jsSideNav = $('#pageTop');
    Stickyfill.add(jsSideNav);
  }
}

export function waitFunc() {
  const wait = ms => new Promise(resolve => setTimeout(() => resolve(), ms))
  wait(1000)
    .then(() => {
      console.log(1)
      return wait(1000)
    }).then(() => {
      console.log(2)
      return wait(1000)
    }).then(() => {
      console.log(3)
    })
}

