export function resizeMovie() {
  let $w = $(window),
    bw = 1200, //基準にする横幅
    bh = (bw / 16) * 9, //基準にする高さ(16:9)
    w = $w.width(), //表示サイズ(幅)
    h = $w.height(), //表示サイズ(高さ)
    mw = w, //動画サイズ(幅)
    mh = Math.round(bh * (mw / bw)); //動画サイズ(高さ)
  if (mh < h) { //動画の高さが表示サイズの高さより小さかったら
    mh = h; //表示サイズの高さに変更
    mw = Math.round(bw * (mh / bh)); //高さに合わせて横幅変更
  }
  $('#movieArea').css({
    width: mw,
    height: mh,
    marginTop: (h - mh) / 2,
    marginLeft: (w - mw) / 2
  });

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}