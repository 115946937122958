//トップページ　キービジュアルスライダー

import slick from 'slick-carousel';
import 'jquery.easing';

export function idxFunc() {
  let index = $('#indexTop').length;

  if (index) {

    $(".slider").animate({
      opacity: 1
    }, 1500, "easeInQuart");

    $('.slider').slick({
      autoplay: true,
      autoplaySpeed: 11000,
      speed: 5000,
      dots: false,
      arrows: false,
      fade: true,
    }).on('beforeChange',function(event, slick, currentSlide, nextSlide){
      if(nextSlide > 0 ) {
        $('.slider').slick('slickSetOption', 'autoplaySpeed', 7000, true)
      }
    });

    let header = $('#header');
    $(window).on("scroll", function () {
      let scrollHeight = $('#mainVisual').height() + 100;
    
      if ($(window).scrollTop() > scrollHeight) {
        header.addClass('show');
      } else {
        header.removeClass('show');
      }
    });

  }
}