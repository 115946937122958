//トップページ　キービジュアルスライダー

export function contactFunc() {
  let contact = $('#contact').length;

  if (contact) {

    let faqCategory = $('.faqCategory');

    faqCategory.on('click', function () {
      let article = $(this).index();

      $(".faqList").removeClass("active");
      $(".faqList").eq(article).addClass("active");//同じ順番のdlにactive

      faqCategory.removeClass('active');
      $(this).addClass('active');

      return false;
    });

    const Target = $('.isEmpty');
    $('.contactFormCon select').each(function (){
      if ($(Target).val() !== ""){
        $(this).removeClass('isEmpty');
      } else {
        $(this).addClass('isEmpty');
      }
    });
    $(Target).on('change', function(){
      if ($(Target).val() !== ""){
        $(this).removeClass('isEmpty');
      } else {
        $(this).addClass('isEmpty');
      }
    });

    $('.mwform-checkbox-field-text').each(function () {
      var text = $(this).html();
      $(this).html(text.replace('&lt;br&gt;', '<br>'));
    });

    let offsetTxt, headerHeight;
    if ($('.contactFormCon span').hasClass('eroorTxt')) {
      offsetTxt = $('.eroorTxt').parent().offset().top;
      headerHeight = $('#header').outerHeight();
      $('html,body').animate({
        scrollTop: offsetTxt - headerHeight
      }, 500);
      return false;
    }

  }
}