import {
  no_scroll,
  return_scroll
} from '../common/_common';

import slick from 'slick-carousel';

export function parallaxFunc() {
  let windowSize = $(window).innerWidth();

  if (windowSize >= 768) {
    var rellax = new Rellax('.parallaxImg', {
      center: true,
      speed: 3
    });
  } else {
    var rellax = new Rellax('.parallaxImg', {
      center: true,
      speed: 1
    });
  }
}

export function loaderFunc() {
  $('body,html').animate({
    scrollTop: 0
  }, 10);
  $('#loader').addClass('hide');
}

export function fadeInFunc() {
  const options = {
    rootMargin: "0px 0px -100px"
  };

  const callback = (function (changes) {
    for (let change of changes) {
      let target = change.target;
      if (change.isIntersecting) {
        target.classList.add('active');
        observer.unobserve(target);
      }
    }
  });

  const observer = new IntersectionObserver(callback, options);

  const targets = document.querySelectorAll('.fadeIn');
  for (let target of targets) {
    observer.observe(target);
  }

  const fadeTargets = document.querySelectorAll('.fade');
  for (let target of fadeTargets) {
    observer.observe(target);
  }
}

export function contentSlideFunc() {
  $('.contentSlider').each(function (i) {
    var $_t = $(this);
    let slideClass = $_t.addClass('slide' + i)

    //スライダー部分
    slideClass.slick({
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 3000,
      dots: false,
      arrows: false,
      fade: true,
    }).slick('slickPause');

    function triggerScroll(targetObj) {
      let targetFlag = false;

      let scrollTop = $(window).scrollTop();
      let scrollBottom = scrollTop + $(window).height();
      let targetTop = targetObj.offset().top;
      let targetBottom = targetTop + targetObj.height();

      if (scrollBottom > targetTop && scrollTop < targetBottom) {
        if (!targetFlag) {
          targetObj.slick('slickPlay');
          targetFlag = true;
        }
      }

      let setTimer = false;
      $(window).on('scroll', function () {
        if (setTimer !== false) {
          clearTimeout(setTimer);
        }
        setTimer = setTimeout(() => {
          scrollTop = $(window).scrollTop();
          scrollBottom = scrollTop + $(window).height();
          targetTop = targetObj.offset().top;
          targetBottom = targetTop + targetObj.height();

          if (scrollBottom > targetTop && scrollTop < targetBottom) {
            if (!targetFlag) {
              targetObj.slick('slickPlay');
              targetFlag = true;
            }
          }
        }, 200);

      });
    }
    triggerScroll(slideClass);
  });

  $('.contentArrowSlider').each(function (i) {
    var $_t = $(this);
    let slideClass = $_t.addClass('slide' + i)

    //スライダー部分
    slideClass.slick({
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 600,
      dots: false,
      arrows: true,
      prevArrow: '<button class="slide-arrow prev-arrow"></button>',
      nextArrow: '<button class="slide-arrow next-arrow"></button>'
    }).slick('slickPause');

    function triggerScroll(targetObj) {
      let targetFlag = false;

      let scrollTop = $(window).scrollTop();
      let scrollBottom = scrollTop + $(window).height();
      let targetTop = targetObj.offset().top;
      let targetBottom = targetTop + targetObj.height();

      if (scrollBottom > targetTop && scrollTop < targetBottom) {
        if (!targetFlag) {
          targetObj.slick('slickPlay');
          targetFlag = true;
        }
      }

      let setTimer = false;
      $(window).on('scroll', function () {
        if (setTimer !== false) {
          clearTimeout(setTimer);
        }
        setTimer = setTimeout(() => {
          scrollTop = $(window).scrollTop();
          scrollBottom = scrollTop + $(window).height();
          targetTop = targetObj.offset().top;
          targetBottom = targetTop + targetObj.height();

          if (scrollBottom > targetTop && scrollTop < targetBottom) {
            if (!targetFlag) {
              targetObj.slick('slickPlay');
              targetFlag = true;
            }
          }
        }, 200);

      });
    }
    triggerScroll(slideClass);
  });
}