import 'jquery.easing';

import {
  no_scroll,
  return_scroll
} from '../common/_common';

let navBtn = $('#navBtn');
let navMenu = $('#navMenu');

export function headerNavFunc() {
  navBtn.on('click', function () {
    $(this).stop().toggleClass('open');
    if ($(this).hasClass('open')) {
      navMenu.addClass('show');
    } else {
      navMenu.removeClass('show');
    }
  });
}

export function headerChangeFunc() {
  const header = document.querySelector('#header');

  const callback = (function (changes) {
    for (let change of changes) {
      if (change.isIntersecting) {
        header.classList.remove('scrollDown');
      } else {
        header.classList.add('scrollDown');
      }
    }
  });

  const observer = new IntersectionObserver(callback);

  const target = document.querySelector('#mainVisual');
  observer.observe(target);
}

export function pageMoveAnkerFunc() {
  $('a[href^="#"]').each(function () {
    let pageUrl = location.href.split('#')[0];
    if (this.href.indexOf(pageUrl + '#') === 0) {
      let id = this.href.split('#')[1];
      $(this).on('click', function () {
        navBtn.stop().removeClass('open');
        if (!navBtn.hasClass('open')) {
          navMenu.removeClass('show');
          return_scroll();
        }
        navMenu.removeClass('show');
        let headerHeight = $('#header').outerHeight(true);
        let position = $('#' + id).offset().top - headerHeight;
        $('body,html').animate({
          scrollTop: position
        }, 1500, 'easeInOutQuint');
        return false;
      });
    }
  });
}
